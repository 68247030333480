<template>
  <v-app :style="{ backgroundImage: `url('${backgroundImage}')` }">
   <v-app-bar app color="transparent" elevation="0">

     <v-spacer></v-spacer>
      
      <h4 v-if="user">{{displayName}}</h4> <a v-if="user" style="margin: 0 20px; color:black;" @click="onLogout"> logga ut</a>
     
     <v-btn  v-if="!user" 
     class="white--text text--lighten-3"
    
    
     color="#192514"
   to="/login"
  elevation="2"
>Login</v-btn>
   </v-app-bar>
    <v-main>

      <router-view @updateBackground="updateBackground"></router-view>
    </v-main>

  </v-app>
</template>

<script>

import {mapState} from 'vuex'
import { db } from './plugins/FirebaseAuthPlugin'

export default {
    components: {
    
  },
   computed: {
    ...mapState(['user']),
    displayName () {
      return this.user ? this.user.email : ''
    }
  },
  created () {
    console.log("created", this.user)
    if(!localStorage.background)
    {
      db
        .collection('settings')
        .doc('settings')
        .get()
        .then(doc => {
          this.logo = doc.data().logo;
          this.menuBackground = doc.data().menuBackground;
          this.updateBackground(this.menuBackground);
          this.persist()
        });
    }
    else{
        this.updateBackground(localStorage.background);
    }
     
  },
  methods:{
         persist() {
      localStorage.logo = this.logo;
      localStorage.background = this.menuBackground;
    },
      updateBackground(backgroundUrl) {
        console.log(backgroundUrl)
        if(this.backgroundImage != backgroundUrl)
        {
        this.backgroundImage = backgroundUrl;
        
        }
      },
     async onLogout () {
      
      await this.$auth.logout();
        this.$router.push("/")

    }
},
    data: () => ({
      logo: '',
      menuBackground: '',
      backgroundImage: '',
      title:'',
      items: ['Foo', 'Bar', 'Fizz', 'Buzz']
    })
  }
</script>


<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-size: cover;
  
  background-blend-mode: darken;
 
  transition: 0.8s;
}
</style>