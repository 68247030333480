import store from '../store'
import Firebase from 'firebase'

const config = {
  apiKey: "AIzaSyBdiVuX7XqUR7CiL4l-7VpV67LyGAr4a0I",
  authDomain: "alvdalen.firebaseapp.com",
  databaseURL: "https://alvdalen.firebaseio.com",
  projectId: "alvdalen",
  storageBucket: "alvdalen.appspot.com",
  messagingSenderId: "457943194817",
}
const firebase = Firebase.initializeApp(config)
const auth = firebase.auth()
export const db = firebase.firestore()

export default {
  install: (Vue) => {
   
    
    db.settings({
        timestampsInSnapshots: true
      });
   
    Vue.prototype.$auth = {
      login: async (username, pass) => {
        return auth.signInWithEmailAndPassword(username, pass)
      },
      logout: async () => {
        await auth.signOut()
      },
      register: async (email, password) => {
        return await auth.createUserWithEmailAndPassword(email, password)
      },
      addUserRecord: async (data) =>{
        console.log("addUserRecord", auth.currentUser.uid)
        db.collection("users").doc(auth.currentUser.uid).set(data);
      },
      updateUserFinishedCategory: async (categoryId, answers) => {
        console.log("updateUserScore", auth.currentUser.uid, answers)
        db.collection("users").doc(auth.currentUser.uid).update({
          finishedCourses: Firebase.firestore.FieldValue.arrayUnion(categoryId),
          ['Answers'+categoryId]: answers
      })},
      updateUserCompleted: async () => {
        console.log("updated")
        db.collection("users").doc(auth.currentUser.uid).update({
          completed: true,
          timestamp: firebase.firestore.FieldValue.serverTimestamp()
      })},
      getUserRecord : async () => {
        return await db.collection("users").doc(auth.currentUser.uid).get();
    },
    forgotEmail : async (email) => {
      return await auth.sendPasswordResetEmail(email);
    }
    }

    auth.onAuthStateChanged(user => {
      store.commit('updateUser',{ user })
    })
  }
}