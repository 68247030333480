<template>
        <v-container fluid fill-height>
        <v-layout
          justify-center
          align-center column
        >    
<!-- <v-img
  lazy-src="../assets/UK_Logo_Transparent_Bak_Svart_653px.png"
  max-height="150"
  max-width="150"
  src="../assets/UK_Logo_Transparent_Bak_Svart_653px.png"
></v-img> -->

      <v-col md="8">
     <h2 class="white--text mb-4 mt-6 display-2 text-xs-center " style="font-family: 'Trebuchet MS', sans-serif !important;">INTRODUKTION</h2>
         <h1 class="white--text mb-4 display-3 mt-6 text-xs-center " style="font-family: 'Trebuchet MS', sans-serif !important;"> 
ÄLVDALENS KOMMUN
</h1>
<h2 class="white--text mb-4 mt-6 text-xs-center " style="font-family: 'Trebuchet MS', sans-serif !important;"> 
VÄLKOMMEN
</h2>
 <v-row no-gutters>
  <v-col md="6" xs="12">
        <v-btn v-if="!user" class="white--text text--lighten-3"
       x-large
    
     color="#192514"
  
  elevation="2"
    to="/login"
>Logga in</v-btn>

  


    </v-col>
      <v-col md="6" xs="12">
           <v-btn v-if="!user" class="white--text text--lighten-3"
    x-large
    
    color="rgb(144, 22, 61)"
    to="register"
  
  elevation="2"
>Registrera</v-btn>
    </v-col>
   
 </v-row>
    </v-col>
    
       
      

        </v-layout>
      </v-container>
</template>

<script>
// @ is an alias to /src

import {mapState} from 'vuex'
export default {
  name: 'Home',
  components: {
  },
   computed: {
    ...mapState(['user']),
    displayName () {
      return !this.user ? this.user.email : '';
    }
    
  },
   watch: {
    user (auth) {
      if(auth){
        this.$router.replace("menu")
      }
    }},
}
</script>
